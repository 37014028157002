import { DOCX, getFileObject, PDF } from '@harbour-enterprises/superdoc';
import axios from 'axios';
import { convertBase64ToBlob, convertBlobToBase64 } from './utils/file-utils';
import { createQueryString } from './utils/helpers';

/**
 * @typedef {Object} FileMetadata
 * @property {string} name File name
 * @property {string} type MIME type
 * @property {number} size File size in bytes
 * @property {number} createdAt Creation timestamp
 * @property {UserInfo} createdBy Creator information
 * @property {number} positionNumber Order position
 */

/**
 * @typedef {Object} UserInfo
 * @property {string} id User identifier
 * @property {string} email User email
 * @property {string} name User full name
 */

/**
 * @typedef {Object} ProcessedFile
 * @property {string} id File identifier
 * @property {File} file Downloaded file object
 * @property {FileMetadata} metadata File metadata
 */

/**
 * @typedef {Object} DocumentRecord
 * @property {string} id Document identifier
 * @property {string} name Document name
 * @property {UserInfo} createdBy Creator information
 * @property {UserInfo[]} collaborators Document collaborators
 * @property {string} status Document status
 */

/**
 * @typedef {Object} DocumentWithFiles
 * @property {string} superdocId Document identifier
 * @property {DocumentRecord} document Document information
 * @property {string} title Document title
 * @property {ProcessedFile[]} files Processed file array
 * @property {UserInfo[]} collaborators Document collaborators
 */

/**
 * @typedef {Object} CollaborationConfig
 * @property {string} url WebSocket URL
 * @property {string} token Authentication token
 */

/**
 * @typedef {Object} UploadResponse
 * @property {string} id File ID
 * @property {string} upload_url Signed upload URL
 */

const API_ENDPOINTS = Object.freeze({
  documents: '/api/documents',
  convert: '/v2/documents/convert',
  agreementFiles: '/api/agreements',
});

const log = (...args) => console.debug('🦋📄\n\t[SuperDoc]', ...args, '\n🦋📄');

const SUPERDOC_EXTENSIONS = {
  [DOCX]: 'docx',
  [PDF]: 'pdf',
};

/**
 * List all documents with optional filtering
 * @async
 * @param {Object} [filter] Optional filter parameters
 * @param {string} [filter.status] Document status
 * @param {string} [filter.type] Document type
 * @returns {Promise<DocumentRecord[]>} List of documents
 * @example
 * const docs = await listDocuments({ status: 'active' });
 */
export const listDocuments = async (filter = {}) => {
  const filterString = createQueryString(filter);
  const url = `${API_ENDPOINTS.documents}${filterString ? `?${filterString}` : ''}`;
  const { data, status } = await axios.get(url);
  return status === 200 ? data : [];
};

/**
 * Get a single document with downloaded files
 * @async
 * @param {string} id Document ID
 * @returns {Promise<DocumentWithFiles>} Document with processed files
 * @throws {Error} If document not found or files cannot be downloaded
 * @example
 * const document = await getDocument('doc_123');
 */
export const getDocument = async (id) => {
  if (!id) return;

  const document = await getDocumentRecord(id);

  // When getting a document, we no longer need to .docx file itself as we're loading from collaboration data
  const files = document.files.map((file) => {
    return {
      id: file.id,
      type: SUPERDOC_EXTENSIONS[file.type],
    };
  });

  return {
    ...document,
    files,
  };
};

export const updateDocument = async (id, payload) => {
  if (!id) return;
  const { data } = await axios.put(`${API_ENDPOINTS.documents}/${id}`, payload);
  return data;
};

/**
 * Upload file to document storage
 * @async
 * @param {File} file File to upload
 * @param {string} url Signed upload URL
 * @returns {Promise<UploadResponse>} Upload result
 * @throws {Error} If upload fails
 * @example
 * const response = await uploadFile(fileObject, signedUrl);
 */
const uploadFile = async (file, url) => {
  const { name, type: mimeType } = file;
  log('Uploading file:', name, 'to', url);

  try {
    const response = await axios.put(url, file, {
      headers: { 'Content-Type': mimeType },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        log(`[${name}] Upload progress: ${percentCompleted}%`);
      },
    });

    log('File uploaded:', name, response.data);
    return response.data;
  } catch (error) {
    log('File upload failed:', name, error);
    throw error;
  }
};

/**
 * Download file from URL with progress tracking
 * @async
 * @param {string} url Download URL
 * @param {string} [filename] Custom filename
 * @param {(progress: number) => void} [onProgress] Progress callback
 * @returns {Promise<File>} Downloaded file
 * @throws {Error} If download fails
 * @example
 * const file = await downloadFile(url, 'document.docx',
 *   (progress) => console.log(`${progress}%`)
 * );
 */
const downloadFile = async (url, filename, onProgress) => {
  if (!url) {
    throw new Error('Download URL is required');
  }

  try {
    // Validate URL and ensure it's properly encoded
    const encodedUrl = encodeURI(decodeURI(url));

    const response = await axios.get(encodedUrl, {
      responseType: 'blob',
      headers: {
        Accept: '*/*',
        'Cache-Control': 'no-cache',
      },
      validateStatus: (status) => status < 500, // Handle HTTP errors manually
      onDownloadProgress: (progressEvent) => {
        if (onProgress && progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onProgress(percentCompleted);
        }
      },
    });

    // Handle HTTP errors
    if (response.status !== 200) {
      throw new Error(`Download failed with status ${response.status}: ${response.statusText}`);
    }

    const blob = response.data;
    const type = response.headers['content-type'] || blob.type;

    // Extract filename from Content-Disposition or URL if not provided
    if (!filename) {
      const disposition = response.headers['content-disposition'];
      if (disposition?.includes('filename=')) {
        filename = disposition.split('filename=')[1].replace(/["']/g, '').trim();
      } else {
        filename = decodeURIComponent(url.split('/').pop().split('?')[0]);
      }
    }

    // Validate blob data
    if (!blob || blob.size === 0) {
      throw new Error('Received empty file data');
    }

    return new File([blob], filename, { type });
  } catch (error) {
    console.error('File download failed:', {
      url,
      status: error.response?.status,
      message: error.message,
      headers: error.response?.headers,
    });
    throw error;
  }
};

/**
 * Create a new document record
 * @async
 * @param {File} document The document data
 * @returns {Promise<DocumentResponse>} Created document data
 */
const createDocumentRecord = async (file, mode) => {
  const { name, type, size } = file;

  const payload = {
    name,
    file: { name, type, size },
    state: mode,
  };

  const response = await axios.post(API_ENDPOINTS.documents, payload);
  return response.data;
};

/**
 * Create new document record with file
 * @async
 * @param {File} fileObject The file object to upload and start a new document
 * @returns {Promise<DocumentResponse>} Created document
 * @example
 * const doc = await createDocument(fileObject);
 */
export const createDocument = async ({ file, mode }) => {
  // 1. Create the document record
  const newDocument = await createDocumentRecord(file, mode);

  // 2. Upload the document initial file
  const fileToUpload = newDocument.files[0];
  await uploadFile(file, fileToUpload.upload_url);

  log('Document created:', {
    name: newDocument.name,
    documentId: newDocument.id,
    fileId: fileToUpload.id,
  });

  const files = newDocument.files.map(() => ({
    id: fileToUpload.id,
    type: SUPERDOC_EXTENSIONS[file.type],
    data: file,
    isNewFile: true,
  }));

  return {
    ...newDocument,
    files,
  };
};

/**
 * List files associated with document
 * @async
 * @param {string} id Document ID
 * @returns {Promise<{items: FileResponse[], total: number}>} Paginated file list
 * @example
 * const files = await listFilesRecords('doc_123');
 */
export const listFilesRecords = async (id) => {
  const { data } = await axios.get(`${API_ENDPOINTS.documents}/${id}/files`);
  return data.items;
};

/**
 * Get document record by ID
 * @async
 * @param {string} id Document ID
 * @returns {Promise<DocumentRecord>} Document record
 * @throws {Error} If document not found
 * @example
 * const record = await getDocumentRecord('doc_123');
 */
export const getDocumentRecord = async (id) => {
  const { data } = await axios.get(`${API_ENDPOINTS.documents}/${id}`);
  return data;
};

// Collaboration service URLs by environment
const COLLABORATION_URLS = {
  localdev: 'ws://localhost:3050',
  livetest: 'wss://staging-superdoc-collaboration.myharbourshare.com',
  staging: 'wss://staging-superdoc-collaboration.myharbourshare.com',
  canary: 'wss://canary-superdoc-collaboration.myharbourshare.com',
  production: 'wss://superdoc-collaboration.myharbourshare.com',
};

/**
 * Get the environment-specific collaboration service URL
 * @param {string} superdocId - The document ID
 * @returns {string} The WebSocket URL for collaboration
 */
export const getCollaborationServiceUrl = (superdocId, environment = 'localdev') => {
  if (!superdocId) throw new Error('Document ID is required');

  const env = import.meta.env.VITE_ENV || environment;
  const baseUrl = COLLABORATION_URLS[env] || COLLABORATION_URLS.production;
  const finalUrl = `${baseUrl}/docs/${superdocId}`;
  console.debug('[getCollaborationServiceUrl]: Final constructed URL:', finalUrl);

  return finalUrl;
};

/**
 * Get collaboration authentication token
 * @async
 * @returns {Promise<string>} Authentication token
 * @example
 * const token = await getLivetestToken();
 */
export const getLivetestToken = async () => {
  const currentHost = window.location.host;
  if (currentHost.endsWith('uc.r.appspot.com')) {
    const response = await axios.get('/collaboration-token');
    return response.data;
  }
  return 'token';
};

/**
 * SuperDoc preview data from the backend returns signed URLs. We need to
 * download those files and return the file objects in the preview data.
 * @param {Array[Object]} superdocPreviewData The superdoc preview data
 * @returns {Array[Object]} The superdoc preview data with file objects
 */
export const loadSuperdocPreviewData = async (superdocPreviewData) => {
  const loadedFiles = [];
  superdocPreviewData.forEach((doc) => {
    const { url, title } = doc;

    const filePromise = getFileObject(url, title, DOCX);
    loadedFiles.push(filePromise);
  });

  const result = await Promise.all(loadedFiles);

  // Update preview data to include file objects
  const dataWithFileObjects = superdocPreviewData.map((doc, index) => {
    return {
      ...doc,
      data: result[index],
    };
  });

  return dataWithFileObjects;
};

const getExternalApiUrl = (env) => {
  const possibleEnvs = {
    staging: 'api-staging.',
    livetest: 'api-staging.',
    canary: 'api-canary.',
    'default-production': 'api.',
    localdev: '',
  };

  if (!(env in possibleEnvs)) throw new Error(`Unable to get external API URL for env ${env}`);
  if (env === 'localdev') return 'http://localhost:9090';
  else return `https://${possibleEnvs[env]}myharbourshare.com`;
};

/**
 * Export a SuperDoc to PDF
 * @param {Blob} docxFiles A .docx file blob
 * @param {string} superdocName The superdoc name
 * @returns {Blob} The PDF blob
 */
export const exportSuperdocPDF = async (docxFiles, superdocName, env) => {
  const filename = `${superdocName}.pdf`;
  const documentBase64 = await convertBlobToBase64(docxFiles);

  // Generate the external API URL which depends on gae env
  const url = `${getExternalApiUrl(env)}${API_ENDPOINTS.convert}`;
  const { data } = await axios.post(
    url,
    {
      file_base64: documentBase64,
      filename,
    },
    { withCredentials: true },
  );

  const { file_base64 } = data;
  const pdfBlob = convertBase64ToBlob(file_base64, 'application/pdf');
  return pdfBlob;
};

/**
 * Get document files for an agreement or a link. Requires at least one of the two parameters.
 * @param {string | null} agreementId The agreement id
 * @param {string | null} linkId The link Id
 * @returns {Promise<Array>} The files for the agreement or link
 */
export const getFilesForAgreement = async (agreementId, linkId) => {
  let url = `${API_ENDPOINTS.agreementFiles}/${agreementId}/files`;

  if (linkId) url += `/link/${linkId}`;

  const result = await axios.get(url);
  return result.data;
};

/**
 * All exposed services
 * @returns {SuperdocServiceType} The SuperDoc service
 */
export const SuperdocService = {
  listDocuments,
  createDocument,
  getDocument,
  updateDocument,
  getCollaborationServiceUrl,
  getLivetestToken,
  loadSuperdocPreviewData,
  exportSuperdocPDF,
  getFilesForAgreement,
};
