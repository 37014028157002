import Vue from 'vue';
import { ModalProgrammatic as Modal, ToastProgrammatic as Toast } from 'buefy';
import { defineStore } from 'pinia';

import { useHarbourStore } from '@/stores/harbour-store';
import { fieldAnnotationHelpers } from '@harbour-enterprises/superdoc';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import dateFormat from 'dateformat';
import SuperdocContainer from '@components/SuperdocContainer/SuperdocContainer.vue';


export const useSuperdocStore = defineStore('superdoc', {
  state: () => ({
    harbourStore: useHarbourStore(),
    draftsStore: useDraftsStore(),
    activeSuperdoc: null,
    currentUrlParams: new URLSearchParams(window.location.search),
  }),

  getters: {
    hasSuperDocBeta: (state) => {
      const currentParams = Object.fromEntries(state.currentUrlParams.entries()) || {};
      if ('ignoresuperdoc' in currentParams) return false;
      return !!state.harbourStore.getContextDict?.superdoc_beta;
    },
  },

  actions: {
    /**
     * Creates file object from file url.
     * @param url The file url.
     * @param name The file name.
     * @param type The file mimetype.
     * @returns File object.
     */
    async createFileObject(url, name, type) {
      return await fetch(url)
        .then((resp) => resp.blob())
        .then((blob) => new File([blob], name, { type }))
        .catch(() => null);
    },

    /**
     * Adds annotation to superdoc editor.
     * @param options.editor The SuperEditor instance.
     * @param options.input The input object.
     * @param options.pos The position to insert.
     * @param options.inputId The input id (optional, used for checkboxes).
     */
    addSuperdocEditorAnnotation({
      editor,
      input,
      pos,
      inputId = null,
      defaultValue = null,
      multipleImage = false,
      editorFocus = false,
    }) {
      let fieldId = inputId ?? input.id;
      let fieldType = input.itemfieldtype;
      let fieldColor = input.itemcolor;
      let displayLabel = input.itemdisplaylabel;
      let rawHtml = input.itemdefaultvalue;

      let typeMap = {
        SIGNATUREINPUT: 'signature',
        IMAGEINPUT: 'image',
        CHECKBOXINPUT: 'checkbox',
        HTMLINPUT: 'html',
        URLTEXTINPUT: 'link',
        DEFAULT: 'text',
      };
      let type = typeMap[fieldType] ?? typeMap.DEFAULT;

      if (type === 'signature') {
        displayLabel = 'Signature';
      }
      if (type === 'checkbox') {
        displayLabel = 'X';
      }

      if (defaultValue) {
        displayLabel = defaultValue;
      }

      let attrs = this.createSuperdocEditorAnnotationAttrs({
        type,
        displayLabel,
        fieldId,
        fieldType,
        fieldColor,
        rawHtml,
        multipleImage
      });

      editor.commands.addFieldAnnotation(pos, attrs, editorFocus);
    },

   /**
    * Creates attrs object for superdoc editor annotation.
    * @param options.type The annotation type.
    * @param options.displayLabel The display label.
    * @param options.fieldId The field ID.
    * @param options.fieldType The field type.
    * @param options.fieldColor The field color.
    * @returns The attrs object.
    */
    createSuperdocEditorAnnotationAttrs({
      type = 'text',
      displayLabel,
      fieldId,
      fieldType,
      fieldColor = '#980043',
      rawHtml = null,
      multipleImage = false,
    }) {
      let requiredAttrs = [displayLabel, fieldId, fieldType];
      let isValid = requiredAttrs.every((attr) => !!attr);

      if (!isValid) {
        throw new Error('Required attributes are missing');
      }

      let attrs = {
        type,
        displayLabel,
        fieldId,
        fieldType,
        fieldColor,
        rawHtml,
        multipleImage,
      };

      return attrs;
    },

   /**
    * Updates attrs for superdoc editor annotations.
    * @param options.editor The editor.
    * @param options.fieldIdOrArray The field ID or array of field IDs.
    * @param options.attrs The attributes.
    */
    updateSuperdocEditorAnnotations({
      editor,
      fieldIdOrArray,
      attrs = {},
    }) {
      editor?.commands.updateFieldAnnotations(fieldIdOrArray, attrs);
    },

    /**
     * Updates attrs for superdoc editor particular annotation.
     * @param options.editor The editor.
     * @param options.annotation The annotation node.
     * @param options.attrs The attributes.
     */
    updateSuperdocEditorAnnotationAttrs({
      editor,
      annotation,
      attrs = {},
    }) {
      editor?.commands.updateFieldAnnotation(annotation, attrs);
    },

   /**
    * Deletes superdoc editor annotations.
    * @param options.editor The editor.
    * @param options.fieldIdOrArray The field ID or array of field IDs.
    */
    deleteSuperdocEditorAnnotations({
      editor,
      fieldIdOrArray,
    }) {
      editor?.commands.deleteFieldAnnotations(fieldIdOrArray);
    },

    /**
    * Set `highlighted` attr for annotations matching predicate.
    * @param options.editor The editor.
    * @param options.predicate The predicate function.
    * @param options.highlighted The highlighted attribute.
    */
    setSuperdocEditorAnnotationsHighlighted({
      editor,
      predicate = () => false,
      highlighted = true,
    }) {
      editor?.commands.setFieldAnnotationsHighlighted(predicate, highlighted);
    },

    /**
     * Preview SuperEditor annotations with input values.
     * @param options.editor The editor instance.
     * @param options.source The source (linkBuilder, signerLink).
     * @param options.options The additional props that may be required by a specific method.
     */
    previewSuperdocEditorAnnotations({
      editor,
      source,
      options = {},
    }) {
      let strategy = {
        linkBuilder: () => {
          this.previewEditorAnnotationsInLinkBuilder(editor, options);
        },
        signerLink: () => {
          this.previewEditorAnnotationsInSignerLink(editor, options);
        },
        default: () => {
          throw new Error('Not implemented.');
        },
      };

      let previewHandler = strategy[source] ?? strategy.default;

      previewHandler();
    },

    /**
     * Preview SuperEditor annotations with input values in Link Builder modal.
     * @param editor The editor instance.
     * @param options.customInputs
     * @param options.hiddenConditionalInputs
     * @param options.referenceAttachments
     */
    async previewEditorAnnotationsInLinkBuilder(editor, {
      customInputs,
      hiddenConditionalInputs,
      referenceAttachments,
    } = {}) {
      let annotations = fieldAnnotationHelpers.getAllFieldAnnotations(editor.state);

      if (!annotations.length) {
        return;
      }

      let hiddenAnnotationsIds = annotations
        .filter((annotation) => !!hiddenConditionalInputs[annotation.node.attrs.fieldId])
        .map((annotation) => annotation.node.attrs.fieldId);

      this.deleteSuperdocEditorAnnotations({ editor, fieldIdOrArray: hiddenAnnotationsIds });

      let multipleUploadValueCount = 0;
      let updatedInputId = null;

      // Exclude hidden/removed annotations.
      annotations = annotations.filter((annotation) => !hiddenAnnotationsIds.includes(annotation.node.attrs.fieldId));

      for (let annotation of annotations) {
        let { type, fieldId, fieldType } = annotation.node.attrs;

        let newValue = null;
        let input = customInputs.find((input) => input.itemid === fieldId);

        if (!input) {
          const isCheckboxInput = (input) => input.itemfieldtype === 'CHECKBOXINPUT';
          let checkboxInputs = customInputs.filter(isCheckboxInput);
          for (let checkboxInput of checkboxInputs) {
            if (newValue) break;
            for (let option of checkboxInput.itemoptions) {
              if (option.itemid === fieldId) {
                newValue = checkboxInput.itemlinkvalue[option.itemid] || '  ';
                break;
              }
            }
          }
        }

        newValue = newValue || input?.itemlinkvalue || '';

        if (!newValue) {
          continue;
        }

        let isImageField = type === 'image' || fieldType === 'IMAGEINPUT';
        let isDateField = ['DATEINPUT', 'SIGNDATEINPUT'].includes(input?.itemfieldtype) &&
          input?.itemformat &&
          /^(\d{4})-(\d{1,2})-(\d{1,2})$/.test(newValue);

        if (isDateField) {
          newValue = this.getFormattedDate(newValue, input.itemformat);
        }

        const getBase64ForImageInput = (attachmentId) => {
          let imageAttachment = referenceAttachments.find((attachment) => attachment.id === attachmentId);
          return imageAttachment?.previewbase64 || '';
        };

        if (isImageField) {
          if (!input) continue;
          if (updatedInputId && updatedInputId !== input.itemid) {
            multipleUploadValueCount = 0;
          }
          let imageIndex = input.multiple ? multipleUploadValueCount : 0;
          newValue = getBase64ForImageInput(input.itemlinkvalue[imageIndex]?.referenceattachmentid);
          multipleUploadValueCount++;
          updatedInputId = input.itemid;
        }

        await this.fillEditorAnnotationForPreview(editor, {
          annotation,
          value: newValue,
        });
      }
    },

    /**
     * Preview SuperEditor annotations with input values in Signer Link.
     * @param editor The editor instance.
     * @param options.customInputs
     * @param options.signerInputs
     * @param options.hiddenConditionalInputs
     * @param options.visibleIneligibleItems
     * @param options.previousSignerData
     * @param options.itemValues
     * @param options.userContentAttachments
     * @param options.allSignersAttachments
     * @param options.agreementData
     * @param options.userInfo
     */
    async previewEditorAnnotationsInSignerLink(editor, {
      customInputs,
      signerInputs,
      hiddenConditionalInputs,
      visibleIneligibleItems,
      previousSignerData,
      itemValues,
      userContentAttachments,
      allSignersAttachments,
      referenceAttachments,
      agreementData,
      userInfo,
      linkDisplayId,
    } = {}) {
      let annotations = fieldAnnotationHelpers.getAllFieldAnnotations(editor.state);

      if (!annotations.length) {
        return;
      }

      let hiddenPreviewItems = this.getEditorHiddenPreviewItemsInSignerLink({
        hiddenConditionalInputs,
        previousSignerData,
        customInputs,
        visibleIneligibleItems,
        itemValues,
        annotations,
      });

      let hiddenAnnotationsIds = annotations
        .filter((annotation) => {
          const isHidden = hiddenPreviewItems[annotation.node.attrs.fieldId]
          const isConditionallyHidden = hiddenConditionalInputs[annotation.node.attrs.fieldId];
          return isHidden || isConditionallyHidden;
        })
        .map((annotation) => annotation.node.attrs.fieldId);

      // Exclude hidden/removed annotations.
      annotations = annotations.filter(
        (annotation) => !hiddenAnnotationsIds.includes(annotation.node.attrs.fieldId)
      );

      let { deletedAnnotationsIds } = this.processEditorAnnotationsInSignerLink({
        editor,
        annotations,
        agreementData,
        customInputs,
        previousSignerData,
        userInfo,
      });

      const allHiddenAnnotations = [
        ...deletedAnnotationsIds, 
        ...hiddenAnnotationsIds,
      ];

      // Delete all hidden annotations.
      this.deleteSuperdocEditorAnnotations({ editor, fieldIdOrArray: allHiddenAnnotations });

      // Get all annotations with updated positions and attributes 
      // after deleting/processing and before filling with values.
      annotations = fieldAnnotationHelpers.getAllFieldAnnotations(editor.state);

      // Exclude hidden/removed annotations (just in case).
      annotations = annotations.filter((a) => !allHiddenAnnotations.includes(a.node.attrs.fieldId));

      const currentSignerAnnotationsMap = this.buildEditorSignerAnnotationsMap({
        annotations,
        agreementData,
        userInfo,
      });
      
      await this.previewEditorSignerAnnotationsInSignerLink({
        editor,
        annotations,
        signerInputs,
        customInputs,
        previousSignerData,
        itemValues,
        userContentAttachments,
        allSignersAttachments,
      });

      await this.previewEditorDocumentAnnotationsInSignerLink({
        editor,
        annotations,
        customInputs,
        referenceAttachments,
        linkDisplayId,
      });

      // Delete empty images (AO and other signers).
      const imagesWithEmptySrc = fieldAnnotationHelpers.findFieldAnnotations((node) => {
        const { type, fieldId, fieldType, imageSrc } = node.attrs;
        const annotation =  { node, pos: null };
        const isOwnerField = !!this.findDocumentOwnerFieldByAnnotation({ annotation, customInputs });
        const isCurrentSignerField = !!currentSignerAnnotationsMap.get(fieldId);
        const isImageType = type === 'image' || fieldType === 'IMAGEINPUT';
        return isImageType && !imageSrc && (isOwnerField || !isCurrentSignerField);
      }, editor.state);
      
      editor.commands.deleteFieldAnnotationsByNode([...imagesWithEmptySrc]);
    },

    async previewEditorSignerAnnotationsInSignerLink({
      editor,
      annotations,
      signerInputs,
      customInputs,
      previousSignerData,
      itemValues,
      userContentAttachments,
      allSignersAttachments,
    }) {
      let multipleUploadValueCount = 0;
      let updatedInputId = null;

      for (let annotation of annotations) {
        let { type, fieldId, fieldType } = annotation.node.attrs;

        let completedAnnotations = previousSignerData?.aggregateditemvalues
          ? Object.keys(previousSignerData?.aggregateditemvalues)
          : [];

        let isPreviouslyCompletedAnnotation = completedAnnotations.includes(fieldId);
        let input = signerInputs.find((input) => input.itemid === fieldId);
        let newValue = null;

        // Get the value from the previous signer data if it exists.
        if (isPreviouslyCompletedAnnotation) {
          let previousSignerResponses = previousSignerData.aggregateditemvalues;
          if (previousSignerResponses[fieldId]) {
            newValue = previousSignerResponses[fieldId];
          }
        // get AO annotation value
        } else if (customInputs.custominputs.find(input => input.itemid === fieldId)) {
          newValue = customInputs.custominputs.find(input => input.itemid === fieldId).itemlinkvalue
        } else if (fieldType === 'YESNOINPUT' && Array.isArray(itemValues[fieldId]) && itemValues[fieldId].length) {
          newValue = itemValues[fieldId][0] === 'YES' ? 'Yes' : 'No';
        } else {
          // Get current user's value.
          newValue = itemValues[fieldId] || '';
          if (fieldType === 'CHECKBOXINPUT') {
            // Blank space if checkbox unchecked == empty string.
            newValue = itemValues[fieldId] || '  ';
          }
        }

        if (!newValue) {
          continue;
        }

        let isImageField = type === 'image' || fieldType === 'IMAGEINPUT';
        let isDateField = ['DATEINPUT', 'SIGNDATEINPUT'].includes(input?.itemfieldtype) &&
          input?.itemformat &&
          /^(\d{4})-(\d{1,2})-(\d{1,2})$/.test(newValue);

        if (isDateField) {
          newValue = this.getFormattedDate(newValue, input.itemformat);
        }

        const getBase64ForImageInput = (inputId, attachmentId) => {
          let allAttachments = [...userContentAttachments, ...allSignersAttachments];
          let imageAttachment = allAttachments.find((attachment) =>
            attachment.id ? attachment.id === attachmentId : attachment.itemid === inputId
          );
          return imageAttachment?.thumbnail || '';
        };

        if (isImageField) {
          if (!input) continue;
          if (updatedInputId && updatedInputId !== input.itemid) {
            multipleUploadValueCount = 0;
          }

          let inputValue = itemValues[input.itemid]
            || previousSignerData?.aggregateditemvalues[input.itemid]
            || [];

          let imageIndex = input.multiple ? multipleUploadValueCount : 0;
          newValue = getBase64ForImageInput(
            input.itemid,
            inputValue[imageIndex]?.userattachmentid,
          );
          if (inputValue.length) multipleUploadValueCount++;
          updatedInputId = input.itemid;
        }

        await this.fillEditorAnnotationForPreview(editor, {
          annotation,
          value: newValue,
        });
      }
    },

    async previewEditorDocumentAnnotationsInSignerLink({
      editor,
      annotations,
      customInputs,
      referenceAttachments,
      linkDisplayId,
    }) {
      let multipleUploadValueCount = 0;
      let updatedInputId = null;

      for (let annotation of annotations) {
        let { type, fieldId, fieldType } = annotation.node.attrs;
        let { custominputs } = customInputs;

        let newValue = null;
        let input = custominputs.find((input) => input.itemid === fieldId);

        if (!input) {
          const isCheckboxInput = (input) => input.itemfieldtype === 'CHECKBOXINPUT';
          let checkboxInputs = custominputs.filter(isCheckboxInput);
          for (let checkboxInput of checkboxInputs) {
            if (newValue) break;
            for (let option of checkboxInput.itemoptions) {
              if (option.itemid === fieldId) {
                newValue = checkboxInput.itemlinkvalue[option.itemid] || '  ';
                break;
              }
            }
          }
        }

        newValue = newValue || input?.itemlinkvalue || '';

        if (!newValue) {
          continue;
        }

        let isImageField = type === 'image' || fieldType === 'IMAGEINPUT';
        let isDateField = ['DATEINPUT', 'SIGNDATEINPUT'].includes(input?.itemfieldtype) &&
          input?.itemformat &&
          /^(\d{4})-(\d{1,2})-(\d{1,2})$/.test(newValue);

        if (isDateField) {
          newValue = this.getFormattedDate(newValue, input.itemformat);
        }

        const getBase64ForImageInput = (attachmentId) => {
          let imageAttachment = referenceAttachments.find((attachment) => attachment.referenceattachmentid === attachmentId);
          return imageAttachment?.attachmentbase64 || '';
        };

        if (isImageField) {
          if (!input) continue;
          if (updatedInputId && updatedInputId !== input.itemid) {
            multipleUploadValueCount = 0;
          }
          let imageIndex = input.multiple ? multipleUploadValueCount : 0;
          newValue = getBase64ForImageInput(input.itemlinkvalue[imageIndex]?.referenceattachmentid);

          multipleUploadValueCount++;
          updatedInputId = input.itemid;
        }

        await this.fillEditorAnnotationForPreview(editor, {
          annotation,
          value: newValue,
        });
      }
    },

    /**
     * Process editor annotations for preview in Signer Link.
     */
    processEditorAnnotationsInSignerLink({
      editor,
      annotations,
      agreementData,
      customInputs,
      previousSignerData,
      userInfo,
    }) {
      // Process annotations.
      let completedAnnotations = previousSignerData?.aggregateditemvalues
        ? Object.keys(previousSignerData?.aggregateditemvalues)
        : [];

      let currentSignerAnnotationsMap = this.buildEditorSignerAnnotationsMap({
        annotations,
        agreementData,
        userInfo,
      });

      let deletedAnnotationsIds = [];
      annotations.forEach((annotation) => {
        let { fieldId } = annotation.node.attrs;
        let foundOwnerField = this.findDocumentOwnerFieldByAnnotation({
          annotation,
          customInputs,
        });

        if (foundOwnerField && foundOwnerField.itemfieldtype === 'SIGNATUREINPUT') {
          deletedAnnotationsIds.push(fieldId);
        } else if (foundOwnerField) {
          editor.commands.setFieldAnnotationsHighlighted((node) => node.attrs.fieldId === fieldId, false);
          return;
        }

        let currentSignerAnnotation = currentSignerAnnotationsMap.get(fieldId);

        // Additional check here if this annotation is
        // from a previously-completed signer then let's not remove.
        if (!currentSignerAnnotation) {
          if (!completedAnnotations.includes(fieldId)) {
            deletedAnnotationsIds.push(fieldId);
          } else {
            // Remove background and border for previous signer annotations.
            editor.commands.setFieldAnnotationsHighlighted((node) => node.attrs.fieldId === fieldId, false);
          }
        }
      });

      return {
        deletedAnnotationsIds,
      };
    },

    /**
     * Gets editor hidden items for preview in Signer Link.
     */
    getEditorHiddenPreviewItemsInSignerLink({
      hiddenConditionalInputs,
      previousSignerData,
      customInputs,
      visibleIneligibleItems,
      itemValues,
    }) {
      // For Signer Link, we need to remove
      // INCOMPLETE annotations that are not for this signer.
      let hiddenPreviewItems = { ...hiddenConditionalInputs };

      // Unhide conditional field if it has value for prev signers.
      // This is a fix that was not in the original code.
      Object.keys(hiddenConditionalInputs).forEach((itemId) => {
        let prevSignerValues = previousSignerData?.aggregateditemvalues || {};
        if (itemId in prevSignerValues) hiddenPreviewItems[itemId] = false;
      });

      // Remove any SIGNDATEINPUT items
      // from document inputs as these are appended last.
      customInputs.custominputs.forEach((item) => {
        if (item.itemfieldtype === 'SIGNDATEINPUT') {
          hiddenPreviewItems[item.itemid] = true;
        }
      });

      // Remove any annotations that aren't previously
      // signed and don't belong to current signer.
      visibleIneligibleItems.forEach((itemid) => {
        if (itemid in previousSignerData?.aggregateditemvalues) return;
        if (itemid in itemValues && itemValues[itemid] != null) return;
        hiddenPreviewItems[itemid] = true;
      });

      // Hide AO conditional items.
      let hiddenCustomConditionalInputs = customInputs.hiddenConditionalInputs || {};

      hiddenPreviewItems = {
        ...hiddenPreviewItems,
        ...hiddenCustomConditionalInputs,
      };

      return hiddenPreviewItems;
    },

    async getImageFileDimensions(base64string) {
      return new Promise((resolve, reject) => {
        const img = new window.Image();
        img.onload = () => resolve({ width: img.width, height: img.height });
        img.onerror = reject;
        img.src = base64string;
      });
    },

    resizeBase64Image(base64Str, newWidth, newHeight) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          const resizedBase64 = canvas.toDataURL();
          resolve(resizedBase64);
        };
        img.onerror = (error) => reject(error);

        // Load the original base64 image
        img.src = base64Str;
      });
    },

    adjustDimensions(width, height, maxWidth, maxHeight) {
      let adjustedWidth = width;
      let adjustedHeight = height;
      const aspectRatio = width / height;

      if (height > maxHeight) {
          adjustedHeight = maxHeight;
          adjustedWidth = Math.round(maxHeight * aspectRatio);
      }

      if (adjustedWidth > maxWidth) {
          adjustedWidth = maxWidth;
          adjustedHeight = Math.round(maxWidth / aspectRatio);
      }

      return { width: adjustedWidth, height: adjustedHeight };
    },

    /**
     * @param editor The editor instance.
     * @param options.annotation
     * @param options.value
     */
    async fillEditorAnnotationForPreview(editor, {
      annotation,
      value,
    }) {
      let { type, fieldId, fieldType } = annotation.node.attrs;
      let newValue = value;

      let isImageField = type === 'image' || fieldType === 'IMAGEINPUT';
      let isSignatureField = type === 'signature' || fieldType === 'SIGNATUREINPUT';
      let isHtmlField = type === 'html' || fieldType === 'HTMLINPUT';
      let isLinkField = type === 'link' || fieldType === 'URLTEXTINPUT';

      if (isImageField) {
        let newSize = null;
        let newImage = newValue;
        if (newValue) {
          const dimensions = await this.getImageFileDimensions(newValue);
          const maxSize = editor.getMaxContentSize();
          newSize = this.adjustDimensions(dimensions.width, dimensions.height, maxSize.width, maxSize.height);
          newImage = await this.resizeBase64Image(newValue, newSize.width, newSize.height);
        };

        this.updateSuperdocEditorAnnotationAttrs({
          editor,
          annotation,
          attrs: {
            imageSrc: newImage,
            size: newSize,
          },
        });
      } else if (isSignatureField) {
        this.updateSuperdocEditorAnnotations({
          editor,
          fieldIdOrArray: fieldId,
          attrs: {
            imageSrc: newValue,
          },
        });
      } else if (isHtmlField) {
        this.updateSuperdocEditorAnnotations({
          editor,
          fieldIdOrArray: fieldId,
          attrs: {
            rawHtml: newValue,
          },
        });
      } else if (isLinkField) {
        let linkUrl = newValue;
        if (!linkUrl.startsWith('http')) linkUrl = `http://${linkUrl}`;
        this.updateSuperdocEditorAnnotations({
          editor,
          fieldIdOrArray: fieldId,
          attrs: {
            linkUrl,
          },
        });
      } else {
        this.updateSuperdocEditorAnnotations({
          editor,
          fieldIdOrArray: fieldId,
          attrs: {
            displayLabel: newValue,
          },
        });
      }
    },

    /**
     * Build annotations map for the current signer in Signer Link.
     * For reference:
     * `src/pages/AgreeLink/hrbr-agree-link.js`, `buildCkeditorSignerAnnotationsMap`
     * @param options.annotations
     * @param options.agreementData
     * @param options.userInfo
     */
    buildEditorSignerAnnotationsMap({
      annotations,
      agreementData,
      userInfo,
    }) {
      let currentSignerAnnotationsMap = new Map();
      let sections = agreementData.agreementcontent?.sections || [];

      let signerInputsWithAnnotations = annotations.reduce((arr, annotation) => {
        let { fieldId } = annotation.node.attrs;

        sections.forEach((section) => {
          let sectionItem = section.sectionitems.find((item) => item.itemid === fieldId);

          // Search for corresponding checkbox option
          // if no section item found.
          let checkboxOptionItem = null;
          if (!sectionItem) {
            let checkboxInputs = section.sectionitems.filter(
              (item) => item.itemtype === 'CHECKBOXINPUT',
            );

            const predicate = (option) => option.itemid === fieldId;
            // Find the checkbox input that contains the option.
            let correspondingGroup = checkboxInputs.find((checkboxInput) =>
              checkboxInput.itemoptions.some(predicate),
            );

            let correspondingOption = correspondingGroup?.itemoptions.find(predicate);
            if (correspondingOption) {
              checkboxOptionItem = correspondingOption;
            }
          }

          if (sectionItem) arr.push([sectionItem, annotation]);
          if (checkboxOptionItem) arr.push([checkboxOptionItem, annotation]);
        });

        return arr;
      }, []);

      signerInputsWithAnnotations.forEach(([item, annotation]) => {
        if (
          item.itemvisiblerequiredindex === userInfo.completionindex ||
          item.itemvisiblerequiredindex === null ||
          item.itemtype === 'CHECKBOXINPUT_NESTED'
        ) {
          currentSignerAnnotationsMap.set(item.itemid, annotation);
        }
      });

      return currentSignerAnnotationsMap;
    },

    /**
     * Find document owner field.
     * @param options.annotation
     * @param options.customInputs
     */
    findDocumentOwnerFieldByAnnotation({
      annotation,
      customInputs,
    }) {
      let { fieldId } = annotation.node.attrs;

      let customInputsKey;
      if ('custominputs' in customInputs) {
        customInputsKey = 'custominputs';
      } else if ('custominputsitems' in customInputs) {
        customInputsKey = 'custominputsitems';
      } else {
        return null;
      }

      let inputs = customInputs[customInputsKey] || [];
      let found = inputs.find((input) => input.itemid === fieldId);

      return found || null;
    },

    /**
     * Adds annotation to superdoc editor.
     * @param options.editor The SuperEditor instance.
     * @param options.input The input object.
     */
    updateMultipleUploadAnnotations({
      editor,
      input,
    }) {
      const isMultiple = input.itemtypeconfig.multiple;
      const count = parseInt(input.itemtypeconfig.imagescount);

      const inputAnnotations = fieldAnnotationHelpers.findFieldAnnotationsByFieldId(input.id, editor.state);
      const mainAnnotation = inputAnnotations[0];

      if (!mainAnnotation) {
        Toast.open({
          message: 'Please place the annotation for the input first',
          type: 'is-info',
          position: 'is-top',
          duration: 3500,
        });
      }

      if (isMultiple) {
        this.updateSuperdocEditorAnnotations({
          editor,
          fieldIdOrArray: input.id,
          attrs: {
            multipleImage: true,
          },
        });

        const lastNodeSize = inputAnnotations[inputAnnotations.length - 1].node.nodeSize;
        let lastNextPos = inputAnnotations[inputAnnotations.length - 1].pos + lastNodeSize;

        if (count > inputAnnotations.length) {
          for (let i = inputAnnotations.length; i <= count - 1; i++) {
            this.addSuperdocEditorAnnotation({
              editor,
              input: input,
              pos: lastNextPos + 1,
              multipleImage: true,
            });
            lastNextPos = lastNextPos + 1 + lastNodeSize;
          }
        } else if (inputAnnotations.length > 1) {
          editor.commands.sliceFieldAnnotations(input.id, count);
        }
      } else {
        editor.commands.sliceFieldAnnotations(input.id, 1);
        this.updateSuperdocEditorAnnotations({
          editor,
          fieldIdOrArray: input.id,
          attrs: {
            multipleImage: false,
          },
        });
      }
    },

    getFormattedDate(value = null, format = '') {
      try {
        let date = new Date();

        if (value) {
          date = new Date(value);

          // Methods on Date Object will convert from UTC to users timezone
          // Set minutes to current minutes (UTC) + User local time UTC offset
          date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
        }

        if (format) {
          return dateFormat(date, format);
        }

        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
        return `${mo} ${da} ${ye}`;
      } catch (e) {
        return value;
      }
    },

    /**
     * Opens the superdoc drafts modal from an uploaded .docx file.
     *
     * @param {{ file: File, parent: VueComponent }} options An object containing the file and parent component
     * @returns {Modal} The buefy modal instance
     */
    openSuperdocFromUpload({ file, parentComponent: parent, isBlankDocument, templateGroupId, ...rest }) {
      const props = {
        file,
        isBlankDocument,
        contextDict: this.harbourStore.getContextDict,
        templateGroupId,
        ...rest,
      };

      const modal = Modal.open({
        component: SuperdocContainer,
        parent,
        fullScreen: true,
        canCancel:  ['outside'],
        customClass: 'superdoc-modal',
        props,
        events: {},
        onCancel: () => {}
      });

      return modal;
    },

    /**
     * Open an existing draft in superdoc
     *
     * @param {Object} draft The draft object
     * @param {VueComponent} parent The parent component
     * @returns {void}
     */
    async openSuperdocFromDrafts ({ parent, mode, editorProps, ...rest }) {
      const props = {
        ...rest,
        editorProps,
        contextDict: this.harbourStore.getContextDict,
        mode: mode || 'draft',
      };

      Modal.open({
        component: SuperdocContainer,
        parent,
        fullScreen: true,
        canCancel:  ['outside'],
        customClass: 'superdoc-modal',
        props,
        events: {},
        onCancel: () => {}
      });
    },


    /**
     * Open the superdoc share modal
     * @param {Object} draft The draft object
     * @param {VueComponent} parent The parent component
     * @returns {void}
     */
    openSuperdocShareModal(draft, parent) {
      const collaborators = this.draftsStore.convertDraftCollaboratorsToSuperdoc(draft);

      Vue.prototype.$openSuperdocShareModal({
        props: {
          superdocId: draft.id,
          superdocTitle: draft.name,
          collaborators,
          parent,
        },
        events: {
          shared: (collaborators) => {
            this.draftsStore.onDocumentShared({
              id: draft.id,
              collaborators
            });
          },
          removeCollaborator: (user) => {
            this.draftsStore.onRemoveCollaborator({
              id: draft.id,
              user,
            });
          },
        },
      });
    },
  },
});
