<script setup>
import '@static/superdoc/dist/style.css';

import Vue, { ref, onMounted, computed, getCurrentInstance, onBeforeUnmount } from 'vue';
import { DialogProgrammatic as Dialog } from 'buefy';
import { ToastProgrammatic as Toast } from 'buefy';
import { HarbourSuperdoc } from '@static/superdoc/dist/harbour-superdoc.es.js';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import { BlankDOCX, getFileObject, DOCX } from '@harbour-enterprises/superdoc';
import { createSuperdocDraft } from '@/domain/drafts/draft';
import { useHarbourStore } from '@/stores/harbour-store';
import { useSuperdocStore } from '@/stores/superdoc-store';
import { publishEvent } from '@/utils/bus';

const { proxy } = getCurrentInstance();
const draftsStore = useDraftsStore();
const harbourStore = useHarbourStore();
const superdocStore = useSuperdocStore();

const emit = defineEmits(['close']);
const props = defineProps({
  file: {
    type: File,
    required: false,
  },
  superdocId: {
    type: String,
    required: false,
  },
  isBlankDocument: {
    type: Boolean,
    required: false,
  },
  superdocName: {
    type: String,
    required: false,
  },
  superdocPromise: {
    type: Promise,
    required: false,
  },
  contextDict: {
    type: Object,
    required: true,
  },
  isStandalone: {
    type: Boolean,
    required: false,
    default: false,
  },
  parent: {
    type: Object,
    required: false,
  },
  mode: {
    type: String,
    required: false,
  },
  template: {
    type: Object,
    required: false,
  },
  agreementId: {
    type: String,
    required: false,
  },
  linkId: {
    type: String,
    required: false,
  },
  linkObject: {
    type: Object,
    required: false,
  },
  superdocProp: {
    type: Object,
    required: false,
  },
  templateGroupId: {
    type: String,
  },
  editorProps: {
    type: Object,
    required: false,
    default: () => ({}),
  }
});

let superdocModalInstance;
const windowWidth = ref(window.innerWidth);
const windowHeight = ref(window.innerHeight);

const currentFile = ref(props.file || null);
const onNewSuperdoc = (superdoc) => {
  const draft = createSuperdocDraft(superdoc, {
    systemEmail: harbourStore.contextDict?.systememail,
  });
  publishEvent('drafts:created', draft);
};

const updateWindowSize = () => {
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
};

const loadBlankFile = async () => {
  let currentDate = superdocStore.getFormattedDate(null, 'mm.dd.yyyy');
  let name = `Untitled - ${currentDate}`;

  return await getFileObject(
    BlankDOCX,
    name,
    DOCX,
  );
};

/**
 * Open the full screen superdoc experience (harbour-superdoc). This will render
 * the Vue3 superdoc modal using SuperdocViewer component.
 *
 * @param file Optional file to initialize with
 */
const init = async (file) => {
  if (props.isBlankDocument) file = await loadBlankFile();
  currentFile.value = file;

  superdocModalInstance = new HarbourSuperdoc({
    elementId: 'superdoc-viewer',
    file,
    environment: import.meta.env.VITE_ENV,
    superdocId: props.superdocId,
    agreementId: props.agreementId,
    linkId: props.linkId || props.editorProps.linkDisplayId,
    superdocName: props.superdocName,
    context: props.contextDict,
    isStandalone: props.isStandalone,
    mode: props.mode,
    linkObject: props.linkObject,
    superdocProp: props.superdocProp,
    onNewSuperdoc,
    onTitleChange,
    onDocumentShared,
    onUserRemoved,
    onContinueToAE,
  });

  superdocModalInstance.on('close', (params) => handleModalClose(params));
};

const handleModalClose = (params) => {
  if (params.isUpdateLink) {
    Toast.open({
      message: 'Link updated successfully',
      type: 'is-success',
      position: 'is-top',
      duration: 3500,
    });
  };
  emit('close');
};

const onDocumentShared = ({ id, collaborators }) => {
  draftsStore.onDocumentShared({ id, collaborators });
};

const onUserRemoved = ({ id, user }) => {
  draftsStore.onRemoveCollaborator({ id, user });
};

const onContinueToAE = (superdoc) => {
  const templateModes = ['template-draft', 'template'];
  const creationMode = templateModes.includes(superdoc.state) ? 'template' : 'agreement';

  const agreementEditorProps = {
    ...props.editorProps,
    parent: proxy,
    creationMode,
    superdoc,
    agreementId: props.agreementId || superdoc.agreement?.id?.value,
    linkDisplayId: props.linkId || superdoc.link?.id?.value || props.editorProps.linkDisplayId,
    fullScreen: true,
  };

  if (props.template) {
    agreementEditorProps.agreementId = props.template.agreement_id;
    agreementEditorProps.templateGroupId = props.template.template_group_id;
  } else if (props.templateGroupId && creationMode === 'template') {
    agreementEditorProps.templateGroupId = props.templateGroupId;
  }

  Vue.prototype.$openAgreementEditorModal({ props: agreementEditorProps })
  emit('close');
};

const onTitleChange = ({ id, title }) => {
  if (props.isStandalone) {
    draftsStore.updateDocumentTitle({ id, name: title });
  } else {
    draftsStore.renameDraft(id, title);
  }
};

onMounted(async () => {
  init(props.file);
  window.addEventListener('resize', updateWindowSize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWindowSize);
});
</script>

<template>
  <div class="superdoc-viewer-full-screen">
    <div class="hrbr-superdoc">
      <div id="superdoc-viewer" class="viewer-container"></div>
    </div>
  </div>
</template>

<style>
.hrbr-superdoc button.modal-close > * {
  color: black !important;
  display: none;
}
.hrbr-superdoc button.modal-close.is-large::before,
.hrbr-superdoc button.modal-close.is-large::after {
  color: black !important;
}
</style>

<style scoped>
.superdoc-loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.superdoc-viewer-full-screen {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hrbr-superdoc {
  border-radius: 8px;
  background-color: white;
  min-width: 98%;
  min-height: 98%;
  height: 98%;
  max-width: 98%;
  max-height: 98%;
  position: relative;
}
.viewer-container {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
}
</style>
