import { defineStore } from 'pinia';
import templateApiService from '@/services/templates-api-service';
import generalApiService from '@/services/general-api-service.js';
import { useHarbourStore } from '@/stores/harbour-store';
import {
  DialogProgrammatic as Dialog,
  ToastProgrammatic as Toast,
  ModalProgrammatic as Modal,
} from 'buefy';
import HrbrTemplateGroupSettingsModal from '@/components/Modals/HrbrTemplateGroupSettings.vue';

/* eslint-disable */
export const useTemplatesStore = defineStore('templatesStore', {
  state: () => ({
    harbourStore: useHarbourStore(),
    name: 'Templates',
    myTemplates: [],
    activeTemplates: [],
    templatesGroups: [],
    userGroupsList: [],
    emails: [],
    //TODO this is a copy of harbourStore.profilePictures. It should be unified
    emailsToProfileImageUrls: {},
    activeTemplateGroup: null,
    templateCustomInputsItems: null,
    isLoadingView: false,
    isLoadingPane: false,
    isGridReady: false,
    lastRequestDateTimeEpochMS: null,
    currentTemplateTitle: 'My templates',
    recentTemplates: [],
    isTemplatesSource: false,
    isLoadingTemplates: false,
    isLoadingRecentTemplates: true,
    recentTemplatesLastRequestEpochMS: null,
    gridReference: null,
    agGridApi: null,
    agGridColumnApi: null,
    lastUsedTemplateGroup: null,
  }),
  actions: {
    isActionsDisabled() {
      return this.activeTemplateGroup == 'TEMPLGROUP-ALL-TEMPLATES' || !this.isGridReady;
    },
    onShareTemplateGroupClick() {
      const props = {
        initUserGroupsList: this.userGroupsList,
        templateGroup: this.getActiveTemplateGroupData(),
      };
      this.openTemplateGroupSettingsModal(props);
    },
    onAddTemplateGroupClick(userEmail) {
      const props = {
        initUserGroupsList: this.userGroupsList,
        userEmail: userEmail,
      };
      this.openTemplateGroupModal(props);
    },
    async createTemplateGroup(templateGroup) {
      const data = await templateApiService.createTemplateGroup(templateGroup);
      return data;
    },
    openTemplateGroupModal(props) {
      Dialog.prompt({
        title: 'New template group',
        inputAttrs: {
          value: '',
          placeholder: 'Template group name',
          maxlength: 300,
          minlength: 1,
        },
        onConfirm: async (newGroupName) => {
          let templateGroup = {
            groupName: newGroupName,
            groupUsersList: [],
          };
          Toast.open({
            duration: 2500,
            message: `Creating your template group...`,
            position: 'is-top',
            type: 'is-success',
          });
          const data = await this.createTemplateGroup(templateGroup);
          let newTemplateGroup = {
            groupId: data.template_group_id,
            groupName: newGroupName,
            groupCreatorEmail: props.userEmail,
            groupUsersList: [],
          };
          Toast.open({
            duration: 2500,
            message: `Success - Template group created`,
            position: 'is-top',
            type: 'is-success',
          });
          this.addTemplateGroup(newTemplateGroup);
          this.setActiveTemplateGroup(newTemplateGroup.groupId);
          this.getTemplates();
          this.getRecentUsedTemplates();
          this.getTemplateGroups(false);
        },
      });
    },
    openTemplateGroupSettingsModal(props) {
      Modal.open({
        component: HrbrTemplateGroupSettingsModal,
        hasModalCard: true,
        props,
        events: {
          'add-template-group': (newTemplateGroup) => {
            this.addTemplateGroup(newTemplateGroup);
            this.setActiveTemplateGroup(newTemplateGroup.groupId);
            this.getTemplates();
            this.getRecentUsedTemplates();
            this.getTemplateGroups(false);
          },
        },
      });
    },
    async getTemplateGroups(setActiveGroup = true) {
      // set admin property and id
      const setAdditionalProps = (group) => {
        const userEmail = this.harbourStore.contextDict?.systememail;
        const members = group.userGroupMembers || [];
        const currentUserAdminMember = members.find((member) => {
          return member.email === userEmail && member.role === 'admin';
        });
        const isGroupAdmin = Boolean(currentUserAdminMember);

        return {
          isGroupAdmin,
          id: group.group_id,
          ...group,
        };
      };

      try {
        this.isLoadingPane = true;

        const data = await templateApiService.getTemplatesGroups();
        const userGroups = data?.userGroups || [];

        this.templatesGroups = data?.templateGroupsList || [];
        this.templatesGroups?.sort(this._templatesByTitle);

        const lastUsedTemplateGroup = data?.lastUsedTemplateGroup;
        if (lastUsedTemplateGroup) {
          this.lastUsedTemplateGroup = lastUsedTemplateGroup;
          if (setActiveGroup) {
            this.setActiveTemplateGroup(lastUsedTemplateGroup.groupId);
          }
        }
        this.userGroupsList = userGroups.map(setAdditionalProps);
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoadingPane = false;
      }
    },

    updateTemplates(newTemplates, originalTemplates) {
      newTemplates.forEach((template) => {
        let updateTemplateIndex = originalTemplates?.findIndex(
          (obj) => obj.agreement_id == template.agreement_id,
        );
        if (updateTemplateIndex != -1) {
          originalTemplates[updateTemplateIndex] = template;
        } else {
          originalTemplates.push(template);
        }
      });
    },

    async getTemplates() {
      try {
        this.isGridReady = false;
        this.isLoadingTemplates = true;

        const data = await templateApiService.getAllTemplates(this.lastRequestDateTimeEpochMS);

        //Store the last datetime we requested the data
        this.lastRequestDateTimeEpochMS = data?.requestdatetimeepochms;

        //If the first time we request this data
        if (this.myTemplates?.length == 0) {
          this.isGridReady = true;
          this.myTemplates = data?.allorgtemplategroups || [];
        } else {
          //Update the template data with the newly added or updated template and template group
          let originalTemplates = this.myTemplates;
          let newTemplates = data?.allorgtemplategroups;
          if (newTemplates) {
            this.updateTemplates(newTemplates, originalTemplates);
          }
          this.myTemplates = originalTemplates;
          this.isGridReady = true;
        }
        this.isLoadingTemplates = false;
        if (!this.activeTemplateGroup) {
          this.activeTemplateGroup = 'TEMPLGROUP-ALL-TEMPLATES';
        }

        this.getActiveTemplates();
        this.loadAvailableProfilePictures();
      } catch (err) {
        console.error(err);

        this.myTemplates = [];
        this.isGridReady = true;
        this.isLoadingTemplates = false;
      }
    },

    _templatesByTitle(a, b) {
      const name1 = a.groupName?.toLowerCase();
      const name2 = b.groupName?.toLowerCase();
      return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
    },
    async loadAvailableProfilePictures() {
      const tempEmails = this.myTemplates?.map((template) => template.original_creator_email) || [];
      const updaterEmails = this.myTemplates?.map((template) => template.updater_email) || [];
      this.emails = [...new Set(tempEmails), ...new Set(updaterEmails)];
      try {
        const data = await generalApiService.getProfilePicture(this.emails);
        data?.userdictlist
          .filter((u) => u.profile_image_url)
          .forEach((u) => {
            this.emailsToProfileImageUrls[u.emails[0]] = u.profile_image_url;
          });
      } catch (e) {
        this.emailsToProfileImageUrls = {};
        console.error(e);
      }
    },
    setActiveTemplateGroup(templateGroupId) {
      this.activeTemplateGroup = templateGroupId;
      this.currentTemplateTitle = this.getActiveGroupTitle();
      this.getActiveTemplates();
    },
    setGroupName(value, id) {
      let activeTemplateGroup = this.templatesGroups?.find(
        (templateGroup) => templateGroup.groupId === id,
      );
      activeTemplateGroup.groupName = value;
      let activeGroupData = this.templatesGroups?.find(
        (templateGroup) => templateGroup.groupId === id,
      );
      activeGroupData.groupName = value;
      this.updateTemplateGroup(activeGroupData);
    },
    getActiveGroupTitle() {
      if (this.activeTemplateGroup == 'TEMPLGROUP-ALL-TEMPLATES') {
        return 'My templates';
      }
      let activeTemplateGroup = this.templatesGroups?.find(
        (templateGroup) => templateGroup.groupId === this.activeTemplateGroup,
      );
      return activeTemplateGroup?.groupName;
    },
    // Time display helper taken from previous mixin
    displayLocalDateTimeFromEpochMs(params) {
      const epochMs = params.value;
      let epoch_ms = epochMs;
      if (epochMs == 'NOW') epoch_ms = Date.now();
      try {
        const eventDate = new Date(epoch_ms);
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        };
        return eventDate.toLocaleTimeString('en-US', options);
      } catch (err) {
        return '-';
      }
    },
    getActiveTemplates() {
      if (this.activeTemplateGroup == 'TEMPLGROUP-ALL-TEMPLATES') {
        const templateGroups = this.templatesGroups || [];
        const templateGroupIds = templateGroups.map((o) => o.groupId);
        this.activeTemplates = this.myTemplates?.filter((template) =>
          templateGroupIds.includes(template.template_group_id),
        );
      } else {
        this.activeTemplates = this.myTemplates?.filter(
          (r) => r.template_group_id == this.activeTemplateGroup,
        );
      }
      this.activeTemplates?.sort((a, b) => b.updatedepochms - a.updatedepochms);
    },
    getActiveTemplateGroupData() {
      return this.templatesGroups?.find(
        (templateGroup) => templateGroup.groupId === this.activeTemplateGroup,
      );
    },
    updateTemplateGroup(templateGroup) {
      templateApiService.updateTemplateGroup(templateGroup);
    },
    async deactivateTemplateGroup(templateGroupId) {
      return await templateApiService.deactivateTemplateGroup(templateGroupId);
    },
    async getPdfPreview(templateId, templateCustomInputsEligible) {
      this.isLoadingView = true;
      const handleCatch = (error) => {
        this.isLoadingView = false;
      };
      const respData = await templateApiService
        .getPdfPreview(templateId, templateCustomInputsEligible)
        .catch(handleCatch);
      return respData;
    },
    async copyDocument({ templateId, fileDisplayId, isTemplate, addCopyToTitle }) {
      const respData = await templateApiService.copyAgreementTemplateRequest({
        templateId,
        fileDisplayId,
        isTemplate,
        addCopyToTitle,
      });
      return respData;
    },
    async deactivateTemplate(templateId) {
      const respData = await templateApiService.deactivateTemplate(templateId);
      return respData;
    },

    async getRecentUsedTemplates() {
      try {
        this.isLoadingRecentTemplates = true;

        const lastRequestMs = this.recentTemplatesLastRequestEpochMS;
        const respData = await templateApiService.getRecentUsedTemplates(lastRequestMs);

        if (respData) {
          let recentTemplates = respData.recenttemplates || [];
          if (recentTemplates.length > 1) {
            recentTemplates.sort((a, b) => b.linkcreatedepochms - a.linkcreatedepochms);
            recentTemplates = recentTemplates.slice(0, 5);
          }
          this.recentTemplates = recentTemplates;
          this.recentTemplatesLastRequestEpochMS = respData.requestdatetimeepochms;
        }
      } catch (err) {
        this.recentTemplates = [];
        console.error(err);
      } finally {
        this.isLoadingRecentTemplates = false;
      }
    },

    getRecentTemplateDetails(templateId) {
      const details = this.myTemplates.find((t) => t.agreement_unique_id === templateId);
      return details || null;
    },

    // Update user's saved settinggs(admin and view state)
    updateSavedSettings() {
      const options = {
        requesttype: 'account-updatesavedsettings',
        settingstoupdate: {
          istemplatessource: this.isTemplatesSource,
        },
      };
      this.harbourStore.updateUserSettings(options);
    },
    templatesGridAutoSize() {
      const gridReference = this.gridReference;
      const gridApi = this.agGridApi;
      const gridColumnApi = this.agGridColumnApi;
      this.harbourStore.autosizeGridByReference(gridReference, gridApi, gridColumnApi);
    },

    setLoadingView(val) {
      this.isLoadingView = val;
    },

    addTemplateGroup(newTemplateGroup) {
      this.templatesGroups.push(newTemplateGroup);
      this.templatesGroups = [...this.templatesGroups].sort(this._templatesByTitle);
    },
  },
});
